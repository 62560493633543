/* .shc-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #1890ff;
    transition: height 1s ease, width 1s ease, background-color 1s ease;
    position: relative;
  }
  
  .shrink {
    height: 50px;
    width: 200px;
    background-color: #1890ff;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .shc-text {
    font-size: 32px;
    font-weight: bold;
    transition: font-size 1s ease, color 1s ease;
    color: black;
  }
  
  .shrink .shc-text {
    font-size: 18px;
    color: white;
  }
  
  .text-transform:before {
    content: 'Get ';
    font-size: inherit;
    transition: all 1s ease;
  }
   */


 
   
  
 

  .shc-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #f0f2f5;
    position: relative;
    overflow: hidden; /* Prevents overflow during animations */
    transition: height 2s ease, width 2s ease, background-color 1s ease;

  }
  
  .shrink {
    width: 300px; /* Final width */
    height: 50px; /* Final height */
    background-color: #f0f2f5;
    border-radius: 5px;
     
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) ; /* Combine translate and scale */
  }
  
  .shc-text {
    display: flex;
    align-items: center;
    position: relative; /* Ensure position context for the child elements */
  }
  
  .get-text {
    font-size: 1px; /* Start small */
    opacity: 0; /* Initially hidden */
    animation: fadeInGrow 2s ease forwards; /* Animation for "Get" */
    animation-delay: 2s; /* Start after 2 seconds */
  }
  
  .shc-main-text {
    font-size: 48px; /* Large font size initially */
    opacity: 1; /* Visible initially */
    animation: fadeOutShrink 2s ease forwards; /* Animation for "Soil Health Card" */
    animation-delay: 2s; /* Start after 2 seconds */
  }
  
  /* Keyframes for the "Get" text */
  @keyframes fadeInGrow {
    0% {
      opacity: 0;
      font-size: 1px;
    }
    100% {
      opacity: 1;
      font-size: 18px;
    }
  }
  
  /* Keyframes for the "Soil Health Card" text */
  @keyframes fadeOutShrink {
    0% {
      opacity: 1;
      font-size: 48px;
    }
    100% {
      opacity: 1; /* Remain visible */
      font-size: 18px;
    }
  }
  


  .home-layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .header {
    background: #f0f2f5;
    padding: 0 16px;
    display: flex;
    align-items: center;
  }
  
  .logo {
    flex: 1;
  }
  
  /* Other styles remain unchanged */
  .banner {
    /* position: relative; */
    width: 100%;
    height: 70vh; /* Set the height of the banner */
    overflow: hidden; /* Hide any overflow */
  }
  
  .banner video {
   
    
    width:100%;
    height: 70vh;
    object-fit: cover; /* Ensure the video covers the entire section */
    z-index: -1; /* Place the video behind the content */
  }
  
  /* .banner-content {
    position: relative;
    z-index: 1; 
    color: white;
    padding: 60px 24px;
    text-align: center;
  }
  
  
  .banner-title {
    margin-bottom: 16px;
    font-size: 48px;
  }
  
  .banner-description {
    font-size: 18px;
  }
   */
  .icons-section {
    margin-top: 400px;
    padding: 40px 24px;
    background: #f0f2f5;
    
      }
  
  .icon-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
  }
  

  .button {
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 5px;
    opacity: 0;
    transform: translateX(-100px);
    transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55), opacity 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  
  .button.visible {
    opacity: 1;
    transform: translateX(100px);
  }
  
  .button:not(.visible) {
    opacity: 0;
    transform: translateX(0px);
  }