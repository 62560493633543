@keyframes fadeInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-20px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeOutToLeft {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(-20px);
    }
  }
  
  .menu-item {
    opacity: 0;
    animation: fadeInFromLeft 0.5s ease forwards;
  }
  
  .menu-item.exit {
    animation: fadeOutToLeft 0.5s ease forwards;
  }
  
  .menu-item.delay-1 {
    animation-delay: 0.1s;
  }
  
  .menu-item.delay-2 {
    animation-delay: 0.4s;
  }
  
  .menu-item.delay-3 {
    animation-delay: 0.1s;
  }
  
  .menu-item.delay-4 {
    animation-delay: 0.2s;
  }
  
  .menu-item.delay-5 {
    animation-delay: 0.7s;
  }
  
  .menu-item.delay-6 {
    animation-delay: 1.0s;
  }
  